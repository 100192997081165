import React, { ReactNode } from 'react';
import Provider from '@shared/providers/Provider';
import { Box, Button, Divider, List, ListItem, Stack } from '@mui/material';
import { LeftNavLogo } from '@shared/LeftNavogo';

interface Props {
  title: string;
  children?: ReactNode;
}

const LeftNav = (
  <Box
    sx={{
      bgcolor: 'primary.dark',
      height: '100vh',
      width: '250px',
      color: 'white',
    }}
  >
    <LeftNavLogo />
    <Divider sx={{ bgcolor: 'white', mx: '16px' }} />
    <List>
      <ListItem sx={{ flexDirection: 'column', gap: '10px' }}>
        <Button variant='contained' color='secondary' href={'/'} fullWidth>
          Home
        </Button>
        <Button
          variant='contained'
          color='secondary'
          href={'/level-2'}
          fullWidth
        >
          Level 2 Consent
        </Button>
        <Button
          variant='contained'
          color='secondary'
          // todo: remove the hardcoded customer_key param before production use
          href={'/bob-uploads?customer_key=example'}
          fullWidth
        >
          Bob Uploads
        </Button>
      </ListItem>
    </List>
  </Box>
);

export default function Page({ title, children }: Props) {
  return (
    <Provider>
      <Stack direction='row'>
        {LeftNav}
        <Box sx={{ width: 1, maxHeight: '100vh', overflow: 'auto' }}>
          <Box
            sx={{
              minHeight: '120px',
              maxHeight: '120px',
              display: 'flex',
              alignItems: 'center',
              px: '16px',
            }}
          >
            <Box sx={{ typography: 'h4' }}>{title}</Box>
          </Box>
          <Divider sx={{ bgcolor: 'primary.light', mx: '16px' }} />
          <Box sx={{ p: '16px' }}>{children}</Box>
        </Box>
      </Stack>
    </Provider>
  );
}
