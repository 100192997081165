import React from 'react';
import { ReactNode, StrictMode } from 'react';
import { ThemeProvider } from '@mui/material';
import { theme } from '@theme/theme';

interface Props {
  children?: ReactNode;
}

export default function Provider({ children }: Props) {
  return (
    <StrictMode>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StrictMode>
  );
}
