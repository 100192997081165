import { PaletteOptions } from '@mui/material';

export const palette: PaletteOptions = {
  primary: {
    main: '#0070F0',
    dark: '#002D60',
  },
  secondary: {
    main: '#3FEDB9',
  },
};
