import React from 'react';
import { Box } from '@mui/material';

export const LeftNavLogo = () => {
  return (
    <Box sx={{ height: '120px', fill: 'white' }}>
      <svg id='MDNLogo' viewBox='0 120 500 500'>
        <path
          className='cls-1'
          d='M216.68,249.58c0-4.16-3-7.61-6.96-8.32v-18.98c3.96-.71,6.96-4.16,6.96-8.32,0-4.67-3.79-8.46-8.46-8.46-4.16,0-7.61,3-8.32,6.96h-18.98c-.71-3.96-4.16-6.96-8.32-6.96s-7.61,3-8.32,6.96h-18.98c-.71-3.96-4.16-6.96-8.32-6.96-4.67,0-8.46,3.79-8.46,8.46,0,4.16,3,7.61,6.96,8.32v18.98c-3.96.71-6.96,4.16-6.96,8.32s3,7.61,6.96,8.32v18.98c-3.96.71-6.96,4.16-6.96,8.32,0,4.67,3.79,8.46,8.46,8.46,4.16,0,7.61-3.01,8.32-6.96h18.98c.71,3.96,4.16,6.96,8.32,6.96s7.61-3.01,8.32-6.96h18.98c.71,3.96,4.16,6.96,8.32,6.96,4.67,0,8.46-3.79,8.46-8.46,0-4.16-3-7.61-6.96-8.32v-18.98c3.96-.71,6.96-4.16,6.96-8.32ZM206.72,222.27v18.98c-3.58.64-6.38,3.54-6.87,7.17h-12.4c1.09-5.26.88-9.3.88-9.3l-14.23-6.21v-10.63c3.47-.62,6.21-3.35,6.83-6.82h18.98c.62,3.47,3.35,6.2,6.82,6.82ZM145.29,215.45h18.98c.62,3.47,3.36,6.2,6.83,6.82v10.63l-14.23,6.21s-.21,4.04.88,9.3h-12.4c-.5-3.63-3.29-6.53-6.87-7.17v-18.98c3.47-.62,6.2-3.35,6.82-6.82ZM145.29,283.7c-.62-3.47-3.35-6.2-6.82-6.82v-18.98c3.35-.6,6.02-3.18,6.75-6.48h13.26c1.18,4.08,3.2,8.54,6.64,12.29,0,0,3.11,2.22,5.97,3.17v10.01c-3.47.62-6.21,3.35-6.83,6.82h-18.98ZM199.9,283.7h-18.98c-.62-3.47-3.36-6.2-6.83-6.82v-10.01c2.86-.95,5.97-3.17,5.97-3.17,3.44-3.75,5.46-8.21,6.64-12.29h13.26c.74,3.31,3.4,5.88,6.75,6.48v18.98c-3.47.62-6.2,3.35-6.82,6.82Z'
        />
        <g>
          <path
            className='cls-1'
            d='M229.13,206.19h5.42l5.34,14.01,5.4-14.01h5.37v20h-3.39v-16.61h-.06l-6.07,16.61h-2.49l-6.07-16.61h-.06v16.61h-3.39v-20Z'
          />
          <path
            className='cls-1'
            d='M255.21,206.19h13.25v3.22h-9.69v4.92h9.18v3.22h-9.18v5.42h10.2v3.22h-13.76v-20Z'
          />
          <path
            className='cls-1'
            d='M272.56,206.19h5.42l5.34,14.01,5.4-14.01h5.37v20h-3.39v-16.61h-.06l-6.07,16.61h-2.49l-6.07-16.61h-.06v16.61h-3.39v-20Z'
          />
          <path
            className='cls-1'
            d='M298.63,206.19h7.8c.75,0,1.5.09,2.23.27.73.18,1.39.47,1.96.88.57.41,1.04.93,1.4,1.57.36.64.54,1.41.54,2.32,0,1.13-.32,2.06-.96,2.8-.64.73-1.48,1.26-2.51,1.58v.06c.62.08,1.2.25,1.74.52.54.27.99.62,1.37,1.05s.67.91.89,1.47c.22.56.32,1.15.32,1.79,0,1.09-.22,2-.65,2.73-.43.73-1,1.31-1.71,1.75-.71.44-1.52.76-2.43.95-.91.19-1.84.28-2.78.28h-7.2v-20ZM302.19,214.33h3.25c1.19,0,2.08-.23,2.67-.69.59-.46.89-1.07.89-1.82,0-.87-.31-1.51-.92-1.94-.61-.42-1.6-.64-2.95-.64h-2.94v5.08ZM302.19,223.14h3.28c.45,0,.94-.03,1.46-.08.52-.06.99-.19,1.43-.4.43-.21.79-.5,1.07-.89s.42-.91.42-1.57c0-1.05-.34-1.79-1.03-2.2-.69-.41-1.76-.62-3.21-.62h-3.42v5.76Z'
          />
          <path
            className='cls-1'
            d='M316.94,206.19h13.25v3.22h-9.69v4.92h9.18v3.22h-9.18v5.42h10.2v3.22h-13.76v-20Z'
          />
          <path
            className='cls-1'
            d='M334.2,206.19h6.95c.96,0,1.89.09,2.78.27.89.18,1.69.49,2.39.92.7.43,1.25,1.02,1.67,1.75.41.73.62,1.67.62,2.8,0,1.45-.4,2.67-1.2,3.64-.8.98-1.95,1.57-3.43,1.78l5.31,8.84h-4.29l-4.63-8.48h-2.6v8.48h-3.56v-20ZM340.53,214.67c.51,0,1.02-.02,1.53-.07.51-.05.97-.16,1.4-.34.42-.18.77-.45,1.03-.82s.4-.88.4-1.54c0-.58-.12-1.05-.37-1.41s-.56-.63-.96-.81c-.4-.18-.83-.3-1.31-.35s-.95-.08-1.4-.08h-3.08v5.42h2.77Z'
          />
          <path
            className='cls-1'
            d='M229.05,240.09h7.88c1.32,0,2.59.2,3.81.61,1.22.41,2.31,1.02,3.25,1.85.94.83,1.7,1.87,2.26,3.12.56,1.25.85,2.73.85,4.42s-.32,3.2-.97,4.45c-.65,1.25-1.49,2.29-2.51,3.11-1.03.82-2.17,1.43-3.42,1.84-1.25.41-2.48.61-3.69.61h-7.46v-20ZM235.26,256.87c1.11,0,2.16-.13,3.15-.38.99-.25,1.85-.65,2.58-1.19.74-.54,1.31-1.23,1.74-2.09.42-.86.64-1.9.64-3.12s-.19-2.24-.57-3.11c-.38-.87-.9-1.57-1.57-2.1-.67-.54-1.46-.93-2.37-1.19-.91-.25-1.92-.38-3.01-.38h-3.25v13.56h2.66Z'
          />
          <path
            className='cls-1'
            d='M250.49,240.09h13.25v3.22h-9.69v4.92h9.18v3.22h-9.18v5.42h10.2v3.22h-13.76v-20Z'
          />
          <path
            className='cls-1'
            d='M267.75,240.09h12.91v3.22h-9.35v5.26h8.81v3.22h-8.81v8.31h-3.56v-20Z'
          />
          <path
            className='cls-1'
            d='M283.97,240.09h13.25v3.22h-9.69v4.92h9.18v3.22h-9.18v5.42h10.2v3.22h-13.76v-20Z'
          />
          <path
            className='cls-1'
            d='M301.23,240.09h4.72l9.69,14.86h.06v-14.86h3.56v20h-4.52l-9.89-15.34h-.06v15.34h-3.56v-20Z'
          />
          <path
            className='cls-1'
            d='M333.52,244.39c-.38-.55-.88-.95-1.51-1.2-.63-.25-1.3-.38-1.99-.38-.41,0-.81.05-1.2.14-.39.09-.74.25-1.05.45-.31.21-.56.48-.75.81s-.28.72-.28,1.17c0,.68.24,1.2.71,1.55.47.36,1.05.67,1.75.93.7.26,1.46.52,2.29.76.83.25,1.59.58,2.29,1.02.7.43,1.28,1.01,1.75,1.72.47.72.71,1.67.71,2.85,0,1.07-.2,2.01-.59,2.81-.4.8-.93,1.46-1.6,1.99-.67.53-1.45.92-2.33,1.19-.89.26-1.82.4-2.8.4-1.24,0-2.44-.21-3.59-.62-1.15-.41-2.14-1.11-2.97-2.09l2.68-2.6c.43.66,1,1.17,1.71,1.54.71.37,1.45.55,2.25.55.41,0,.83-.06,1.24-.17.41-.11.79-.28,1.13-.51.34-.23.61-.51.82-.86.21-.35.31-.75.31-1.2,0-.74-.24-1.3-.71-1.7-.47-.4-1.06-.73-1.75-1-.7-.27-1.46-.53-2.29-.78-.83-.24-1.59-.58-2.29-1s-1.28-.99-1.75-1.7c-.47-.71-.71-1.65-.71-2.84,0-1.04.21-1.93.64-2.68.42-.75.98-1.38,1.67-1.88.69-.5,1.47-.87,2.36-1.12.88-.25,1.79-.37,2.71-.37,1.05,0,2.08.16,3.07.48.99.32,1.88.85,2.67,1.58l-2.6,2.74Z'
          />
          <path
            className='cls-1'
            d='M339.94,240.09h13.25v3.22h-9.69v4.92h9.18v3.22h-9.18v5.42h10.2v3.22h-13.76v-20Z'
          />
          <path
            className='cls-1'
            d='M229.05,274h4.72l9.69,14.86h.06v-14.86h3.56v20h-4.52l-9.89-15.34h-.06v15.34h-3.56v-20Z'
          />
          <path
            className='cls-1'
            d='M251.54,274h13.25v3.22h-9.69v4.92h9.18v3.22h-9.18v5.42h10.2v3.22h-13.76v-20Z'
          />
          <path
            className='cls-1'
            d='M272.9,277.22h-6.13v-3.22h15.82v3.22h-6.13v16.78h-3.56v-16.78Z'
          />
          <path
            className='cls-1'
            d='M282.78,274h3.9l3.76,14.41h.06l4.49-14.41h3.5l4.41,14.41h.06l3.93-14.41h3.62l-5.82,20h-3.39l-4.63-15.23h-.06l-4.63,15.23h-3.28l-5.91-20Z'
          />
          <path
            className='cls-1'
            d='M311.66,284.08c0-1.6.26-3.06.79-4.36.53-1.31,1.26-2.43,2.19-3.35.93-.92,2.04-1.63,3.33-2.13s2.71-.75,4.25-.75c1.56-.02,3,.21,4.29.69,1.3.48,2.42,1.18,3.36,2.1.94.92,1.68,2.03,2.2,3.33s.79,2.75.79,4.35-.26,2.99-.79,4.27c-.53,1.28-1.26,2.38-2.2,3.31-.94.92-2.06,1.64-3.36,2.16-1.3.52-2.73.79-4.29.81-1.54,0-2.96-.25-4.25-.75s-2.4-1.21-3.33-2.12c-.93-.91-1.66-2.01-2.19-3.29s-.79-2.7-.79-4.27ZM315.39,283.86c0,1.07.17,2.06.49,2.97.33.9.8,1.69,1.4,2.34s1.32,1.18,2.16,1.55c.84.38,1.77.57,2.81.57s1.98-.19,2.83-.57,1.57-.89,2.18-1.55c.6-.66,1.07-1.44,1.4-2.34.33-.9.49-1.89.49-2.97,0-1-.17-1.93-.49-2.8-.33-.87-.8-1.62-1.4-2.27-.6-.65-1.33-1.16-2.18-1.53-.85-.37-1.79-.55-2.83-.55s-1.97.18-2.81.55c-.84.37-1.56.88-2.16,1.53-.6.65-1.07,1.41-1.4,2.27-.33.87-.49,1.8-.49,2.8Z'
          />
          <path
            className='cls-1'
            d='M336.27,274h6.95c.96,0,1.89.09,2.78.27s1.69.49,2.39.92c.7.43,1.25,1.02,1.67,1.75.41.73.62,1.67.62,2.8,0,1.45-.4,2.67-1.2,3.64-.8.98-1.95,1.57-3.43,1.78l5.31,8.84h-4.29l-4.63-8.48h-2.6v8.48h-3.56v-20ZM342.59,282.47c.51,0,1.02-.02,1.53-.07s.97-.16,1.4-.34.77-.45,1.03-.82.4-.88.4-1.54c0-.58-.12-1.05-.37-1.41-.25-.36-.56-.63-.96-.81-.4-.18-.83-.3-1.31-.35-.48-.06-.95-.08-1.4-.08h-3.08v5.42h2.77Z'
          />
          <path
            className='cls-1'
            d='M354.06,274h3.56v8.48h.17l8.28-8.48h4.8l-9.35,9.24,9.97,10.76h-5l-8.67-9.83h-.2v9.83h-3.56v-20Z'
          />
        </g>
      </svg>
    </Box>
  );
};
